import React from "react";
import { Modal, Form, Alert, Button, Spinner, Col, Table, Badge } from 'react-bootstrap';
import moment from 'moment';
import UserService from "../../../services/UserService";

export default class AddUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            error: false,
            loading: false,

            payload: {
                name: '',
                phone: '',
                password: ''
            }
        }
    }

    handleAddUser = async (e) => {
        e.preventDefault();

        const { payload } = this.state;

        this.setState({ loading: true, error: false, success: false });
    
        await UserService.CreateUser(payload).then(() => {
            this.setState({ loading: false, success: true });

            return this.props.handleDone();
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    handleChange = ({ target }) => {
        const { payload } = this.state;
        this.setState({ payload: {...payload, [target.name]: target.value} });
    }

    render() {
        const { success, error, loading, payload, modal } = this.state;
        const { users } = this.props;

        return (
            <>
            <Col md={7}>
                <div className='d-flex justify-content-between'>
                    <p className='font-medium'><i className='fas fa-users'></i> Team Management</p>
                    <Button onClick={() => this.setState({ modal: true })}>Add New</Button>
                </div>

                <div className='bg-white p-4 mt-4'>
                    <Table responsive className='borderless'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Last Connection</th>
                                <th>Account Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                users.map((user, key) => (
                                    <tr>
                                        <td>{key + 1}</td>
                                        <td>{user.name}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.last_connection && moment(user.last_connection).format('H:mm, MMM D')}</td>
                                        <td>
                                            <h5>
                                            <Badge variant='success' className='font-small'>
                                                active
                                            </Badge>
                                            </h5>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </Col>

            <Modal show={modal} onHide={() => this.setState({ modal: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleAddUser}>
                    <Modal.Body>
                        {
                            success && <Alert variant='success'>
                                User added successfully
                            </Alert>
                        }

                        {
                            error && <Alert variant='danger'>
                                Something went wrong try again
                            </Alert>
                        }

                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" placeholder="Eg: John Mandela" required autoComplete='off' value={payload?.name} onChange={this.handleChange} readOnly={loading} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" name="phone" placeholder="Eg: 250 78X XXX XXX" required autoComplete='off' value={payload?.phone} onChange={this.handleChange} readOnly={loading} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="text" name="password" placeholder="********" required autoComplete='off' value={payload?.password} onChange={this.handleChange} readOnly={loading} />
                        </Form.Group>
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <Button type='button' variant="danger" onClick={() => this.setState({ modal: false })} disabled={loading}>
                            Close
                        </Button>
                        <Button type='submit' variant="primary" disabled={loading}>
                        { loading ? <Spinner animation="border" size='sm' /> : 'Submit'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            </>
        );
    }
}