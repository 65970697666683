import React from "react";
import { Form } from 'react-bootstrap';

export default class CheckBox extends React.Component {
    render() {
        const { handleClickBox, activeCheckBox, id } = this.props;
        const active = activeCheckBox.some(value => value === id);

        return (
            <Form.Check type="checkbox" checked={active} onClick={() => handleClickBox(id)} />
        );
    }
}