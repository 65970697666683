import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';
import UserService from '../../services/UserService';
import { setToken } from '../../core/Http';
import Logo from '../../assets/images/logo/logo.png';

export default class SignUpView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            success: false,
            payload: {
                name: '',
                phone: '',
                sector: '',
                country: 'rw',
                password: ''
            }
        }
    }

    handleSignUp = async (e) => {
        e.preventDefault();

        const { payload } = this.state;
        const { history } = this.props;

        this.setState({ loading: true, error: false, success: false });
        
        await UserService.CreateAccount(payload).then(({ data }) => {
            
            setTimeout(() => {
                setToken(data.token);
                history.push('/otp/verify');
            }, 2000);

            return this.setState({ loading: false, success: true });
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    handleChange = ({ target }) => {
        const { payload } = this.state;
        this.setState({ payload: {...payload, [target.name]: target.value} });
    }

    render() {
        const { loading, error, success, payload } = this.state;
        const sectors = ['Retails', 'Food and beverage', 'Professional services'];
        
        return (
            <Container>
                <Row className='justify-content-center'>
                    <Col md={5}>
                        <div className="auth-wrapper mt-5">
                            <Image src={Logo} width='50%' alt='GoCashless' className='mb-5' />

                            <div className="auth-inner bg-white p-5">
                                <Form onSubmit={this.handleSignUp}>
                                    <h3 className='text-center mb-4'>Sign Up</h3>

                                    {
                                        success && <Alert variant='success'>
                                        Account created successfully.
                                    </Alert>
                                    }

                                    {
                                        error && <Alert variant='danger'>
                                        Business Name already in use.
                                    </Alert>
                                    }

                                    <Form.Group>
                                        <Form.Label>Business Name</Form.Label>
                                        <Form.Control type="text" name="name" placeholder="Eg: Africa XYZ" required autoComplete='off' value={payload?.name} onChange={this.handleChange} readOnly={loading} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" name="phone" placeholder="Eg: 2507X XXX XXX" required autoComplete='off' value={payload?.phone} onChange={this.handleChange} readOnly={loading} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Sector</Form.Label>
                                        <Form.Control name="sector" as="select" required onChange={this.handleChange} readOnly={loading}>
                                            <option value="">Choose sector</option>
                                            {
                                                sectors.map(value => (
                                                    <option value={value}>{value}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name="password" placeholder="********" required autoComplete='off' value={payload?.password} onChange={this.handleChange} readOnly={loading} />
                                    </Form.Group>

                                    <Button type='submit' variant="primary" className='btn-block py-2' disabled={loading}>
                                        { loading ? <Spinner animation="border" size='sm' /> : 'Signup'}
                                    </Button>

                                    <p className="forgot-password text-right mt-4">
                                        Already have an account <Link to="/login">Login?</Link>
                                    </p>
                                </Form>
                            </div>

                            <p className='text-center py-5'>&copy; Copyrigh 2021 GoCashless | Allrights reserved.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}