import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';
import UserService from '../../services/UserService';
import { setToken } from '../../core/Http';
import Logo from '../../assets/images/logo/logo.png';

export default class LoginTeamView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            payload: {
                phone: '',
                password: ''
            }
        }
    }

    handleLogIn = async (e) => {
        e.preventDefault();

        const { payload } = this.state;
        const { history } = this.props;

        this.setState({ loading: true, error: false });

        const authPayload = {
            team: 'admin',
            ...payload
        }
        
        await UserService.AuthLogin(authPayload).then(({ data }) => {
            setToken(data.token);
            return history.push('/dashboard');
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    handleChange = ({ target }) => {
        const { payload } = this.state;
        this.setState({ payload: {...payload, [target.name]: target.value} });
    }

    render() {
        const { loading, error, payload } = this.state;
        
        return (
            <Container>
                <Row className='justify-content-center'>
                    <Col md={5}>
                        <div className="auth-wrapper mt-5">
                            <Image src={Logo} width='50%' alt='GoCashless' className='mb-5' />

                            <div className="auth-inner bg-white p-5">
                                <Form onSubmit={this.handleLogIn}>
                                    <h3 className='text-center mb-4'>Log In</h3>

                                    {
                                        error && <Alert variant='danger'>
                                        Invalid combination of phone and password
                                    </Alert>
                                    }

                                    <Form.Group>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" name="phone" placeholder="Eg: 2507X XXX XXX" required autoComplete='off' value={payload?.phone} onChange={this.handleChange} readOnly={loading} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name="password" placeholder="********" required autoComplete='off' value={payload?.password} onChange={this.handleChange} readOnly={loading} />
                                    </Form.Group>

                                    <Button type='submit' variant="primary" className='btn-block py-2' disabled={loading}>
                                        { loading ? <Spinner animation="border" size='sm' /> : 'Login'}
                                    </Button>

                                    <p className="forgot-password text-right mt-4">
                                        Dont't have an account <Link to="/signup">Signup ?</Link>
                                    </p>
                                </Form>
                            </div>

                            <p className='text-center py-5'>&copy; Copyrigh 2021 GoCashless | Allrights reserved.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}