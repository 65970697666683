import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RouteHandler from './core/Route';
import { setToken } from "./core/Http";
import HomeView from './resources/views/HomeView';
import DashboardView from './resources/views/DashboardView';
import ProductView from './resources/views/ProductView';
import OrderListView from './resources/views/OrderListView';
import DatabaseView from './resources/views/DatabaseView';
import CampaignView from './resources/views/CampaignView';
import ProfileView from './resources/views/ProfileView';
import LoginView from './resources/views/LoginView';
import LoginTeamView from './resources/views/LoginTeamView';
import SignUpView from './resources/views/SignUpView';
import OTPVerifyView from './resources/views/OTPVerifyView';

export default class App extends React.Component {
    componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) setToken(token);
    }

    render() {
        return (
            <Router>
                <Switch>
                    <RouteHandler exact path='/' component={HomeView} />
                    
                    <RouteHandler exact path='/dashboard' component={DashboardView} auth={true} />
                    <RouteHandler exact path='/products' component={ProductView} auth={true} />
                    <RouteHandler exact path='/transactions' component={OrderListView} auth={true} />
                    <RouteHandler exact path='/customers' component={DatabaseView} auth={true} />
                    <RouteHandler exact path='/campaigns' component={CampaignView} auth={true} />
                    <RouteHandler exact path='/profile' component={ProfileView} auth={true} />
                    
                    <RouteHandler exact path='/login' component={LoginView} auth={false} />
                    <RouteHandler exact path='/signup' component={SignUpView} auth={false} />
                    <RouteHandler exact path='/otp/verify' component={OTPVerifyView} auth={true} />

                    <RouteHandler exact path='/:workspace/login' component={LoginTeamView} auth={false} />

                    <Route>
                        <div className='container text-center py-5 my-5'>
                            <h2>404 | Not Found</h2>
                            <Link to='/' className='btn btn-primary mt-3 py-2'>Go Back Home</Link>
                        </div>
                    </Route>
                </Switch>
            </Router>
        );
    }
}