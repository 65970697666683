import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Button, Modal, Form, Spinner, Alert, InputGroup, FormControl } from 'react-bootstrap';
import AppLayout from '../layouts/AppLayout';
import ProductService from '../../services/ProductService';
import UserService from '../../services/UserService';
import moment from 'moment';

export default class DashboardView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order: '',
            cart: [],
            invoice: false,
            products: [],
            payload: {
                name: '',
                phone: ''
            },

            loading: false,
            error: false,
            success: false,

            sid: '',

            disabled: false,
        }
    }

    async componentDidMount() {
        const { data: order } = await ProductService.LastBillLOrder();
        const { data } = await ProductService.ListProduct();
        const { data: user } = await UserService.ViewAccount();
        this.setState({ order });
        this.setState({ products: data });
        this.setState({ sid: user.account.sid });
    }

    handleSendBill = async (e) => {
        e.preventDefault();

        const { payload, cart } = this.state;

        this.setState({ loading: true, error: false, success: false });
        
        await ProductService.SendBill(payload, cart).then(() => {
            return this.setState({ loading: false, success: true });
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    handleAddToCart = obj => {
        const { cart } = this.state;
        const exists = cart.some(value => value.id === obj.id);

        if (exists) return;

        obj['count'] = 1;

        cart.push(obj);

        this.setState({ cart });
    }

    handleAddCountToCart = id => {
        const { cart } = this.state;
        const objIndex = cart.findIndex(value => value.id === id);

        if (cart[objIndex].count > 9) return;

        cart[objIndex].count = cart[objIndex].count + 1;
        
        this.setState({ cart });
    }

    handleRemoveCountToCart = id => {
        const { cart } = this.state;
        const objIndex = cart.findIndex(value => value.id === id);

        if (cart[objIndex].count < 2) {
            const removeCart = cart.filter(value => value.id !== id);

            this.setState({ cart: removeCart });
            
            return
        }

        cart[objIndex].count = cart[objIndex].count - 1;
        
        this.setState({ cart });
    }

    handleChange = ({ target }) => {
        const { payload } = this.state;
        this.setState({ payload: {...payload, [target.name]: target.value} });
    }

    handleSearchPhone = async ({ target }) => {
        const phone = `${target.value}`;

        this.setState({ payload: {...this.state.payload, phone} });

        if (phone.length === 10) {
            this.setState({ loading: true });
            const { data } = await ProductService.SearchCustomer(phone);
            this.setState({ payload: {...this.state.payload, name: data.data}, loading: false });

            if (data.data === "") {
                return this.setState({ disabled: false });
            }

            return this.setState({ disabled: true });
        }

        this.setState({ disabled: false });
    }

    render() {
        const { order, cart, invoice, products, payload, loading, error, success, sid, disabled } = this.state;
        const cartTotal = cart.reduce((total, value) => total = total + value.price * value.count, 0);

        return (
            <AppLayout>
                <div className='order py-5'>
                    <Container fluid>
                        <Row>
                            <Col lg={4} className="mobile-view mb-4">
                                <div className='shadow-sm cart bg-white p-4'>
                                    <Row>
                                        <Col md={12}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='font-weight-500 font-medium'>Cart</p>
                                                <Button variant='danger' className='btn-remove-all' onClick={() => this.setState({ cart: [] })}><i className='fas fa-times'></i></Button>
                                            </div>
                                        </Col>

                                        <Col md={12} className='product my-4'>
                                            <Row>
                                                {
                                                    cart.map((value, key) => (
                                                        <Col md={12} key={key}>
                                                            <div className='product-box mb-3'>
                                                                <Image src={value.image} alt={value.name} className='img-fluid mr-3' />
                                                                <p className='font-normal'>
                                                                    {value.name} <br/>
                                                                    <span className='font-weight-500'>FR{value.price}</span>
                                                                </p>

                                                                <div className='buttons'>
                                                                    <Button onClick={() => this.handleRemoveCountToCart(value.id)}>-</Button>
                                                                    <span className='mx-2 font-sm-normal'>{value.count}</span>
                                                                    <Button onClick={() => this.handleAddCountToCart(value.id)}>+</Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Col>

                                        <Col md={12}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='total-label mt-2'>Total</p>
                                                <p className='total-price'>FR{cartTotal}</p>
                                            </div>

                                            {
                                                cartTotal > 0 ? 
                                                    <Button variant='primary' className='btn-block checkout shadow mt-2' onClick={() => this.setState({ invoice: true })}>Check Out</Button>
                                                    : <Button variant='primary' className='btn-block checkout shadow mt-2' disabled>Check Out</Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col lg={8} className='mt-3'>
                                <div className='product'>
                                    <Row>
                                        <Col md={12}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='font-medium'>Order No. #{order}</p>
                                                <p className='font-sm-normal'>{moment().format('H:mm, MMM D')}</p>
                                            </div>
                                        </Col>

                                        <Col md={12} className='mt-4'>
                                            <div className="product-search">
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="basic-addon1">
                                                            <i className='fas fa-search'></i>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <FormControl placeholder="Enter item name" />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <p className='display-block text-right col-md-12 mb-3'>1 of {products.length}</p>
                                        {
                                            products.map((value, key) => (
                                                <Col md={3} className='mb-3' key={key}>
                                                    <div className='shadow-sm cursor bg-white pt-5 pb-3 product-box' onClick={() => this.handleAddToCart(value)}>
                                                        <Image src={value.image} alt={value.name} className='img-fluid' />
                                                        
                                                        <div className='d-flex justify-content-between'>
                                                            <p className='font-weight-400 px-3 mt-4 font-normal'>{value.name}</p>
                                                            <p className='font-weight-400 px-3 mt-4 font-normal'>{value.price} RWF</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </Col>

                            <Col lg={4} className="web-view">
                                <div className='shadow-sm cart bg-white p-4'>
                                    <Row>
                                        <Col md={12}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='font-weight-500 font-medium'>Cart</p>
                                                <Button variant='danger' className='btn-remove-all' onClick={() => this.setState({ cart: [] })}><i className='fas fa-times'></i></Button>
                                            </div>
                                        </Col>

                                        <Col md={12} className='product my-4'>
                                            <Row>
                                                {
                                                    cart.map((value, key) => (
                                                        <Col md={12} key={key}>
                                                            <div className='product-box mb-3'>
                                                                <Image src={value.image} alt={value.name} className='img-fluid mr-3' />
                                                                <p className='font-normal'>
                                                                    {value.name} <br/>
                                                                    <span className='font-weight-500'>FR{value.price}</span>
                                                                </p>

                                                                <div className='buttons'>
                                                                    <Button onClick={() => this.handleRemoveCountToCart(value.id)}>-</Button>
                                                                    <span className='mx-2 font-sm-normal'>{value.count}</span>
                                                                    <Button onClick={() => this.handleAddCountToCart(value.id)}>+</Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Col>

                                        <Col md={12}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='total-label mt-2'>Total</p>
                                                <p className='total-price'>FR{cartTotal}</p>
                                            </div>

                                            {
                                                cartTotal > 0 ? 
                                                    <Button variant='primary' className='btn-block checkout shadow mt-2' onClick={() => this.setState({ invoice: true })}>Check Out</Button>
                                                    : <Button variant='primary' className='btn-block checkout shadow mt-2' disabled>Check Out</Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Modal show={invoice} onHide={() => this.setState({ invoice: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Send Invoice</Modal.Title>
                        </Modal.Header>

                        {
                            !sid ? <Modal.Body>
                                <p className='pb-2 font-normal'>Please Add Your Sender Name <Link to='/profile'>My Profile</Link></p>
                            </Modal.Body>
                            : <Form onSubmit={this.handleSendBill}>
                                <Modal.Body>
                                    {   
                                        success && <Alert variant='success'>
                                            Invoice sent successfully
                                        </Alert>
                                    }

                                    {
                                        error && <Alert variant='danger'>
                                            Incorect phone number
                                        </Alert>
                                    }

                                    <Form.Group>
                                        <Form.Label>Customer Phone</Form.Label>
                                        <Form.Control type="text" name="phone" placeholder="Eg: 07X XXX XXX" required autoComplete='off' value={payload?.phone} onChange={this.handleSearchPhone} readOnly={loading} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" name="name" placeholder="Eg: John Mugisha" required autoComplete='off' value={payload?.name} onChange={this.handleChange} readOnly={loading || disabled} />
                                    </Form.Group>
                                </Modal.Body>
                                
                                <Modal.Footer>
                                    <Button type='button' variant="danger" onClick={() => this.setState({ invoice: false })} disabled={loading}>
                                        Close
                                    </Button>
                                    <Button type='submit' variant="primary" disabled={loading}>
                                        { loading ? <Spinner animation="border" size='sm' /> : 'Submit'}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        }
                    </Modal>
                </div>
            </AppLayout>
        );
    }
}