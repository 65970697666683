import React from 'react';
import { Container, Row, Col, Table, Button, Form, InputGroup } from 'react-bootstrap';
import moment from 'moment';
import ProductService from '../../services/ProductService';
import AppLayout from '../layouts/AppLayout';

export default class CampaigntView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            campaigns: [],
            account: '',

            today: new Date().toISOString().slice(0, 10),

            filters: {
                start_date: new Date().toISOString().slice(0, 10),
                end_date: new Date().toISOString().slice(0, 10)
            }
        }
    }

    async componentDidMount() {
        const { data: campaigns } = await ProductService.ListCampaigns();
        this.setState({ campaigns });
    }
    
    handleChangeFilters = ({ target }) => {
        const { filters } = this.state;
        this.setState({ filters: { ...filters, [target.name]: target.value } })
    }

    handleSubmitFilter = async () => {
        const { filters } = this.state;

        const { data: campaigns } = await ProductService.ListCampaigns(filters.start_date, filters.end_date);
        this.setState({ campaigns });
    }

    render() {
        const { campaigns, today, filters } = this.state;

        return (
            <AppLayout>
                <div className='profile py-5'>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className='d-flex justify-content-between'>
                                    <p className='font-medium'>My Campaigns</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col lg={12}>
                                <div className='p-4 bg-white'>

                                <Row>
                                    <Col md={7} className="mb-3">
                                        <Form.Row>
                                            <Col md={4} className="mb-2">
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text className="px-3">from</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control type="date" name="start_date" value={filters.start_date} max={today} onChange={this.handleChangeFilters} />
                                                </InputGroup>
                                            </Col>

                                            <Col md={4} className="mb-2">
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text className="px-4">To</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control type="date" name="end_date" value={filters.end_date} min={filters.start_date} max={today} onChange={this.handleChangeFilters} />
                                                </InputGroup>
                                            </Col>

                                            <Col md={2}>
                                                <Button 
                                                    variant='success' 
                                                    className="py-2 btn-block" 
                                                    onClick={this.handleSubmitFilter}
                                                >Filter</Button>
                                            </Col>
                                        </Form.Row>
                                    </Col>

                                    <Col md={5}>
                                        <Button variant='default' className='float-right text-primary'>
                                            <i className='fas fa-download'></i> Download
                                        </Button>
                                    </Col>
                                </Row>

                                <Row className="mt-2 mobile-view">
                                    {
                                        campaigns.map((value, key) => (
                                            <Col md={12} key={key}>
                                                <div className="w-100 p-3 shadow-sm border mb-3 cursor" onClick={() => {
                                                    this.setState({ viewPayload: value, viewModal: true })
                                                }}>
                                                    <p className="font-to-normal"><b>{value.phone}</b> <span className="float-right">{moment(value.created_at).format('H:mm, MMM D')}</span></p>
                                                    <p className="font-to-normal mt-2">{value.message}</p>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>

                                <div className="web-view">
                                    <Table responsive className='borderless mt-2'>
                                        <thead>
                                            <tr className='borderless'>
                                                <th>#</th>
                                                <th>Phone</th>
                                                <th>Message</th>
                                                <th>Date / Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                campaigns.map((value, key) => (
                                                    <tr>
                                                        <td>{key + 1}</td>
                                                        <td>{value.phone}</td>
                                                        <td>{value.message}</td>
                                                        <td>{moment(value.created_at).format('H:mm, MMM D')}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        </Table>
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </AppLayout>
        );
    }
}