import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Image, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import UserService from '../../services/UserService';
import Logo from '../../assets/images/logo/logo.png';

class AppLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false,
            account: {},
            loading: true,
        }
    }

    handleLogout = () => {
        UserService.AuthLogout();

        const { history } = this.props;
        history.push('/login');
    }

    async componentDidMount() {
        const { history } = this.props;

        const { data: account } = await UserService.ViewAccount();
        const verified = account?.user?.verified;

        if (verified) {
            return this.setState({ account, loading: false });
        }

        return history.push('/otp/verify');
    }

    handleNavExpand = () => {
        const { menu } = this.state;
        this.setState({ menu: !menu });
    }

    render() {
        const { children } = this.props;
        const { account, menu, loading } = this.state;

        return loading ? null : (
            <>
            <Navbar 
                className='header'
                onToggle={this.handleNavExpand}
                expanded={menu}
                collapseOnSelect
                bg="light"
                sticky='top'
                expand='lg'
            >
                <Container fluid>
                    <Navbar.Brand>
                        <Link to='/dashboard'>
                            <Image src={Logo} className='img-fluid' width='150px' />
                        </Link>
                    </Navbar.Brand>
                    
                    <Navbar.Toggle>
                        {!menu ? (
                            <i className='fas fa-bars'></i>
                        ) : (
                            <i className='fas fa-times'></i>
                        )}
                    </Navbar.Toggle>
                    
                    <Navbar.Collapse>
                        <Nav className="ml-auto">
                            <li className='nav-item'>
                                <Link to='/dashboard' className='nav-link'><i className='fas fa-home'></i> Home</Link>
                            </li>

                            <li className='nav-item'>
                                <Link to='/transactions' className='nav-link'><i className='fas fa-exchange-alt'></i> Transactions</Link>
                            </li>

                            {
                                account?.user?.role === 'admin' &&
                                <>
                                    <li className='nav-item'>
                                        <Link to='/customers' className='nav-link'><i className='fas fa-users'></i> Customers</Link>
                                    </li>

                                    <li className='nav-item'>
                                        <Link to='/campaigns' className='nav-link'><i className='fas fa-bullhorn'></i> Campaigns</Link>
                                    </li>

                                    <li className='nav-item'>
                                        <Link to='/products' className='nav-link'><i className='fas fa-list'></i> Products</Link>
                                    </li>
                                </>
                            }

                            <NavDropdown title={<><i className="fas fa-user mr-1"></i> {account?.account?.name}</>}>
                                <NavDropdown.Item as={Link} to="/profile">My Profile</NavDropdown.Item>
                                <NavDropdown.Item as={Link} onClick={this.handleLogout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            
            <main>{children}</main>
            </>
        );
    }
}

export default withRouter(AppLayout);