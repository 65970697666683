import Http from '../core/Http';
import axios from 'axios';

export default class ProductService {
    static ListProduct() {
        return Http.get('product', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static CreateProduct(payload) {
        return Http.post('product', payload);
    }

    static DeleteProduct(id) {
        return Http.delete(`product/${id}`);
    }

    static LastBillLOrder() {
        return Http.get('product/latest/order', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static SendBill(data, cart) {
        const amount = cart.reduce((total, value) => total = total + value.price * value.count, 0);
        
        const payload = {
            name: data.name,
            phone: `25${data.phone}`,
            cart,
            amount
        }
        
        return Http.post('product/bill', payload);
    }

    static ListOrder(date = null) {
        return Http.get(`product/order?date=${date}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static UpdateOrderStatus(id, status) {
        return Http.patch(`product/order/status/${id}?status=${status}`);
    }

    static ListCustomers(start_date = null, end_date = null) {
        return Http.get(`product/customers?start_date=${start_date}&end_date=${end_date}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static SendCustomerSMS(payload) {
        return Http.post('product/customers/sms', payload);
    }

    static SearchCustomer(phone) {
        return Http.get(`product/customer?phone=25${phone}`);
    }

    static ListCampaigns(start_date = null, end_date = null) {
        return Http.get(`product/customers/sms?start_date=${start_date}&end_date=${end_date}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static UploadImage(file) {
        const url = "https://api.cloudinary.com/v1_1/agaseke-tech/upload";
        
        const formData = new FormData();

        formData.append('file', file);
        formData.append('upload_preset', 'gocashless');
        formData.append('folder', 'product');

        return axios.post(url, formData);
    }
}