import React from 'react';
import { Container, Row, Col, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';
import UserService from '../../services/UserService';
import Logo from '../../assets/images/logo/logo.png';

export default class OTPVerifyView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoading: true,
            account: {},
            otp: '',
            loading: false,
            error: false,
            success: false
        }
    }

    async componentDidMount() {
        const { history } = this.props;

        const { data: account } = await UserService.ViewAccount();
        const verified = account?.user?.verified;

        if (!verified) {
            return this.setState({ account, pageLoading: false });
        }

        return history.push('/dashboard');
    }

    handleVerify = async (e) => {
        e.preventDefault();

        const { payload } = this.state;
        const { history } = this.props;

        this.setState({ loading: true, error: false, success: false });
        
        await UserService.VerifyAccount(payload).then(() => {
            setTimeout(() => {
                history.push('/dashboard');
            }, 2000);

            return this.setState({ loading: false, success: true });
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    handleChange = ({ target }) => {
        const { payload } = this.state;
        this.setState({ payload: {...payload, [target.name]: target.value} });
    }

    render() {
        const { pageLoading, account, loading, error, success, payload } = this.state;

        return pageLoading ? null : (
            <Container>
                <Row className='justify-content-center'>
                    <Col md={5}>
                        <div className="auth-wrapper mt-5">
                            <Image src={Logo} width='50%' alt='GoCashless' className='mb-5' />

                            <div className="auth-inner bg-white p-5">
                                <Form onSubmit={this.handleVerify}>
                                    <h3 className='text-center'>Verify OTP</h3>
                                    <p className='text-center mb-4'>Enter code sent to your phone number <b>({account?.user?.phone})</b></p>

                                    {
                                        success && <Alert variant='success'>
                                        Account verified successfully.
                                    </Alert>
                                    }

                                    {
                                        error && <Alert variant='danger'>
                                        Something went wrong try again.
                                    </Alert>
                                    }

                                    <Form.Group>
                                        <Form.Label>OTP</Form.Label>
                                        <Form.Control type="text" name="otp" placeholder="123456" required autoComplete='off' value={payload?.otp} onChange={this.handleChange} readOnly={loading} />
                                    </Form.Group>

                                    <Button type='submit' variant="primary" className='btn-block py-2' disabled={loading}>
                                        { loading ? <Spinner animation="border" size='sm' /> : 'Verify'}
                                    </Button>
                                </Form>
                            </div>

                            <p className='text-center py-5'>&copy; Copyrigh 2021 GoCashless | Allrights reserved.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}