import React from "react";
import { Link } from 'react-router-dom';
import TextLoop from 'react-text-loop';
import { Container, Row, Col, Button, Navbar, Nav, Form, Image, NavDropdown } from "react-bootstrap";
import Logo from '../../assets/images/logo/logo.png';

export default class HomeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrool: false,
            menu: false
        }
    }

    omponentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 50) {
            this.setState({ scrool: true });
        } else {
            this.setState({ scrool: false });
        }
    }

    handleNavExpand = () => {
        const { menu } = this.state;
        this.setState({ menu: !menu });
    }

    render() {
        const { menu, scrool } = this.state;

        return (
            <>
            <Navbar 
                className={`header-home ${scrool && `scrool`}`}
                onToggle={this.handleNavExpand}
                expanded={menu}
                collapseOnSelect
                bg="light"
                sticky='top'
                expand='lg'
            >
                <Container fluid>
                    <Navbar.Brand>
                        <Link to='/login' className='logo'>
                            <Image src={Logo} className='img-fluid' width='150px' />
                        </Link>
                    </Navbar.Brand>
                    
                    <Navbar.Toggle>
                        {!menu ? (
                            <i className='fas fa-bars'></i>
                        ) : (
                            <i className='fas fa-times'></i>
                        )}
                    </Navbar.Toggle>
                    
                    <Navbar.Collapse>
                        <Nav className="ml-auto ml-5 mr-3">
                            <NavDropdown title="Why GoCashless ?">
                                <NavDropdown.Item>E-Receipt</NavDropdown.Item>
                                <NavDropdown.Item>Digital Menu</NavDropdown.Item>
                                <NavDropdown.Item>Connect With Customers</NavDropdown.Item>
                            </NavDropdown>

                            <li className='nav-item'>
                                <Link to='/' className='nav-link text-white'>Pricing</Link>
                            </li>

                            <li className='nav-item'>
                                <Link to='/' className='nav-link text-white'>Contact</Link>
                            </li>
                        </Nav>

                        <Nav className="ml-auto mr-3">
                            <li className='nav-item'>
                                <Link to='/' className='nav-link text-white'><i className='fas fa-search'></i></Link>
                            </li>

                            <li className='nav-item'>
                                <Link to='/login' className='nav-link text-white'>Login</Link>
                            </li>
                        </Nav>

                        <Form inline>
                            <Button as={Link} to="/signup" variant="outline-info" className="get-started-btn">Get Started <i className='fas fa-arrow-right'></i></Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className='hero'>
                <Container fluid>
                    <Row>
                        <Col lg={6} className='content text-white'>
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <h1 className='title mt-5'>Say Goodbye <br/> to <TextLoop children={['cash', 'paper']} /> 👋</h1>
                                        <p className='description mt-5'>Still running a cash-only business?</p>
                                        <p className='paragraph mt-2'>Let's change that. Start digitalizing your customer journey.</p>

                                        <Button as={Link} to="/signup" variant='outline-warning' className='mt-5'>Get Started <i className='fas fa-arrow-right'></i></Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>

                        <Col lg={6} className='happy-person web-view'></Col>
                    </Row>
                </Container>
            </div>
            </>
        );
    }
}