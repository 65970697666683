import React from 'react';
import { Container, Row, Col, Table, Button, Form, Modal } from 'react-bootstrap';
import moment from 'moment';
import UserService from '../../services/UserService';
import ProductService from '../../services/ProductService';
import AppLayout from '../layouts/AppLayout';

export default class OrderListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            filterday: [],
            account: '',

            today: new Date().toISOString().slice(0, 10),

            filters: {
                date: new Date().toISOString().slice(0, 10),
            },

            viewModal: false,
            viewPayload: {}
        }
    }

    async componentDidMount() {
        const { today } = this.state;

        const { data: account } = await UserService.ViewAccount();
        const { data: orders } = await ProductService.ListOrder();

        const filterday = orders?.filter(
            value => moment(value.created_at).isSame(moment(today).format('YYYY-MM-DD'), 'day')
        );

        this.setState({ orders, filterday, account: account.account.name });
    }

    async handleUpdateStatus(id, status) {
        await ProductService.UpdateOrderStatus(id, status);
        this.componentDidMount();
    }

    handleFilterDate = async ({ target }) => {
        const { filters } = this.state;   
        this.setState({ filters: { ...filters, date: target.value} });

        const { data: orders } = await ProductService.ListOrder(target.value);

        const filterday = orders?.filter(
            value => moment(value.created_at).isSame(moment(target.value).format('YYYY-MM-DD'), 'day')
        );

        this.setState({ filterday, orders });
    }

    render() {
        const { orders, filterday, account, today, filters, viewModal, viewPayload } = this.state;

        return (
            <AppLayout>
                <div className='profile py-5'>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className='d-flex justify-content-between'>
                                    <p className='font-medium'>My Transactions</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col md={3}>
                                <div className="bg-primary text-white text-center py-4 border-radius mb-3">
                                    <i className="fas fa-calculator font-title"></i>

                                    <p className="font-normal mt-3">Total Gross</p>
                                    <p className="font-normal">{filterday.reduce((total, value) => total = total + value.amount, 0)} RWF</p>
                                </div>
                            </Col>

                            <Col md={3}>
                                <div className="bg-success text-white text-center py-4 border-radius mb-3">
                                    <i className="fas fa-thumbs-up font-title"></i>

                                    <p className="font-normal mt-3">Total Paid</p>
                                    <p className="font-normal">{filterday.filter(value => value.status === 'approved').reduce((total, value) => total = total + value.amount, 0)} RWF</p>
                                </div>
                            </Col>

                            <Col md={3}>
                                <div className="bg-danger text-white text-center py-4 border-radius mb-3">
                                    <i className="fas fa-thumbs-down font-title"></i>

                                    <p className="font-normal mt-3">Total Unpaid</p>
                                    <p className="font-normal">{filterday.filter(value => value.status === 'pending' || value.status === 'canceled').reduce((total, value) => total = total + value.amount, 0)} RWF</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col lg={12}>
                                <div className='p-4 bg-white'>

                                <Row>
                                    <Col md={5}>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Control as="select">
                                                        <option>Filter by type</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Control type="date" value={filters.date} max={today} onChange={this.handleFilterDate} />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Col>

                                    <Col md={7}>
                                        <Button variant='default' className='float-right text-primary'>
                                            <i className='fas fa-download'></i> Download
                                        </Button>
                                    </Col>
                                </Row>

                                <Row className="mt-2 mobile-view">
                                    {
                                        orders.map((value, key) => (
                                            <Col md={12} key={key}>
                                                <div className="w-100 p-3 shadow-sm border mb-3 cursor" onClick={() => {
                                                    this.setState({ viewPayload: value, viewModal: true })
                                                }}>
                                                    <p className="font-to-normal"><b>#{value.order_no}</b> <span className="float-right">{value.amount} RWF</span></p>
                                                    <p className="font-to-normal mt-2">{value.name} <span className="float-right">{moment(value.created_at).format('H:mm, MMM D')}</span></p>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>

                                <div className="web-view">
                                    <Table responsive className='borderless'>
                                        <thead>
                                            <tr className='borderless'>
                                                <th>#</th>
                                                <th>Order ID</th>
                                                <th>Customer Name</th>
                                                <th>Customer Phone</th>
                                                <th>Products</th>
                                                <th>Total Amount</th>
                                                <th>Date Time</th>
                                                <th>Payment Status</th>
                                                <th>Sales Person</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                orders.map((value, key) => (
                                                    <tr>
                                                        <td>{key + 1}</td>
                                                        <td>#{value.order_no}</td>
                                                        <td>{value.name}</td>
                                                        <td>{value.phone}</td>
                                                        <td>
                                                            <ul>
                                                                {
                                                                    value.cart.map(cart => (
                                                                        <li>{cart.name} {cart.price * cart.count} RWF</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </td>
                                                        <td>{value.amount} RWF</td>
                                                        <td>{moment(value.created_at).format('H:mm, MMM D')}</td>
                                                        <td>
                                                            {
                                                                (value.status === 'pending' || value.status === 'approved') && <Button variant='success' className='payment-status-btn mr-2' onClick={() => this.handleUpdateStatus(value.id, 'approved')}>
                                                                    <i className='fas fa-check' size='xs'></i>
                                                                </Button>
                                                            }

                                                            {
                                                                (value.status === 'pending' || value.status === 'canceled') && <Button variant='danger' className='payment-status-btn' onClick={() => this.handleUpdateStatus(value.id, 'canceled')}>
                                                                    <i className='fas fa-times' size='xs'></i>
                                                                </Button>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                account === value.user.name ? 'Admin' : value.user.name
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Modal show={viewModal} onHide={() => this.setState({ viewModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Transaction</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>Order ID</b></p>
                                    <p className="font-to-normal mt-1">#{viewPayload?.order_no}</p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>customer Name</b></p>
                                    <p className="font-to-normal mt-1">{viewPayload?.name}</p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>Customer Phone</b></p>
                                    <p className="font-to-normal mt-1">{viewPayload?.phone}</p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>Products</b></p>
                                    <p className="font-to-normal mt-1">
                                        <ul>
                                            {
                                                viewPayload?.cart?.map(cart => (
                                                    <li>{cart.name} {cart.price * cart.count} RWF</li>
                                                ))
                                            }
                                        </ul>
                                    </p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>total Amount</b></p>
                                    <p className="font-to-normal mt-1">{viewPayload?.amount} RWF</p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>Payment Status</b></p>
                                    <p className="font-to-normal mt-1">
                                        {
                                            (viewPayload?.status === 'pending' || viewPayload?.status === 'approved') && <Button variant='success' className='payment-status-btn mr-2' onClick={() => this.handleUpdateStatus(viewPayload?.id, 'approved')}>
                                                <i className='fas fa-check' size='xs'></i>
                                            </Button>
                                        }

                                        {
                                            (viewPayload?.status === 'pending' || viewPayload?.status === 'canceled') && <Button variant='danger' className='payment-status-btn' onClick={() => this.handleUpdateStatus(viewPayload?.id, 'canceled')}>
                                                <i className='fas fa-times' size='xs'></i>
                                            </Button>
                                        }
                                    </p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>Date Time</b></p>
                                    <p className="font-to-normal mt-1">{moment(viewPayload?.created_at).format('H:mm, MMM D')}</p>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="w-100 mb-3">
                                    <p className="font-to-normal"><b>Sales Person</b></p>
                                    <p className="font-to-normal mt-1">
                                        {
                                            account === viewPayload?.user?.name ? 'Admin' : viewPayload?.user?.name
                                        }
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </AppLayout>
        );
    }
}