import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import UserService from '../../services/UserService';
import AddUser from '../components/modal/AddUser';
import ProfileSMS from '../components/form/ProfileSMS';
import ProfileGeneral from '../components/form/ProfileGeneral';
import AppLayout from '../layouts/AppLayout';

export default class ProfileView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 4,
            account: {},
            payload: {},
            users: []
        }
    }

    async componentDidMount() {
        const { data: account } = await UserService.ViewAccount();

        if (account?.user.role === 'admin') {
            const { data: payload } = await UserService.ViewProfile();
            const { data: users } = await UserService.ListUsers();
            
            this.setState({ payload, users, active: 1 });
        }

        this.setState({ account });
    }

    handleDone = async () => {
        const { data: payload } = await UserService.ViewProfile();
        const { data: users } = await UserService.ListUsers();
        
        this.setState({ payload, users });
    }

    render() {
        const { account, payload, active, users } = this.state;

        return (
            <AppLayout>
                <div className='profile py-5'>
                    <Container>
                        <Row>
                            <Col md={4} className='mb-4'>
                                <ListGroup defaultActiveKey="#link1">
                                    {
                                        account?.user?.role === 'admin' &&<>
                                            <ListGroup.Item action active={active === 1 && true} onClick={() => this.setState({ active: 1 })}>
                                                <i className='fas fa-building'></i> Company Profile
                                            </ListGroup.Item>
                                            <ListGroup.Item action active={active === 2 && true} onClick={() => this.setState({ active: 2 })}>
                                                <i className='fas fa-sms'></i> SMS Notification
                                            </ListGroup.Item>
                                            <ListGroup.Item action active={active === 3 && true} onClick={() => this.setState({ active: 3 })}>
                                                <i className='fas fa-users'></i> Team Management
                                            </ListGroup.Item>
                                        </>
                                    }
                                    <ListGroup.Item action active={active === 4 && true} onClick={() => this.setState({ active: 4 })}>
                                        <i className='fas fa-cog'></i> Settings
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>

                            <Col md={1}></Col>

                            {
                                active === 1 && <ProfileGeneral payload={payload} handleDone={this.handleDone} />
                            }

                            {
                                active === 2 && <ProfileSMS payload={payload} handleDone={this.handleDone} />
                            }

                            {
                                active === 3 && <AddUser users={users} closeModal={() => this.setState({ userModal: false })} handleDone={this.handleDone} />
                            }
                        </Row>
                    </Container>
                </div>
            </AppLayout>
        );
    }
}