import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default class RouteHandler extends React.Component {
    render () {
        const { component: Component, auth, ...rest } = this.props;
        const token = localStorage.getItem('token');

        return (
            <Route {...rest}
                render={props => {
                    if (auth !== undefined) {
                        if (auth && !token) {
                            return <Redirect to='/login' />
                        }
    
                        if (!auth && token) {
                            return <Redirect to='/dashboard' />
                        }
                    }

                    return <Component {...props} />
                }}
            />
        )
    }
}