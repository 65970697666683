import React from "react";
import { Link } from 'react-router-dom';
import { Modal, Form, Alert, Spinner, Button } from 'react-bootstrap';
import UserService from "../../../services/UserService";
import ProductService from "../../../services/ProductService";

export default class SendSMS extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            error: false,
            loading: false,
            message: '',
            sid: '',
        }
    }

    async componentDidMount() {
        const { data: user } = await UserService.ViewAccount();
        this.setState({ sid: user.account.sid });
    }

    handleSendSMS = async (e) => {
        e.preventDefault();

        this.setState({ loading: true, error: false, success: false });

        const payload = {
            phone: this.props.activePhone,
            message: this.state.message
        }

        await ProductService.SendCustomerSMS(payload).then(() => {
            return this.setState({ loading: false, success: true });
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    render() {
        const { sid, success, error, loading, message } = this.state;
        const { modal, handleModal } = this.props;

        return (
            <Modal show={modal} onHide={handleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Send SMS</Modal.Title>
                </Modal.Header>

                {
                    !sid ? <Modal.Body>
                    <p className='pb-2 font-normal'>Please Add Your Sender Name <Link to='/profile'>My Profile</Link></p>
                </Modal.Body> : <Form onSubmit={this.handleSendSMS}>
                                    <Modal.Body>
                                        {
                                            success && <Alert variant='success'>
                                                SMS sent successfully
                                            </Alert>
                                        }

                                        {
                                            error && <Alert variant='danger'>
                                                Something went wrong try again
                                            </Alert>
                                        }

                                        <Form.Group>
                                            <Form.Label>Sender Name</Form.Label>
                                            <Form.Control type="text" name="sid" required autoComplete='off' value={sid} readOnly />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as='textarea' placeholder="Eg: Hello There" required autoComplete='off' value={message} onChange={({ target }) => this.setState({ message: target.value })} readOnly={loading} />
                                            <p>Use @CustomerName to insert The Customer Name</p>
                                        </Form.Group>
                                    </Modal.Body>
                                    
                                    <Modal.Footer>
                                        <Button type='button' variant="danger" onClick={handleModal} disabled={loading}>
                                            Close
                                        </Button>
                                        <Button type='submit' variant="primary" disabled={loading}>
                                        { loading ? <Spinner animation="border" size='sm' /> : 'Submit'}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                }
            </Modal>
        );
    }
}