import React from 'react';
import { Container, Row, Col, Image, Button, Modal, Form, Alert, Spinner } from 'react-bootstrap';
import AppLayout from '../layouts/AppLayout';
import ProductService from '../../services/ProductService';

export default class ProductView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addModal: false,
            products: [],
            payload: {
                name: '',
                price: '',
                image: ''
            },

            loading: false,
            error: false,
            success: false,
        }
    }

    async componentDidMount() {
        const { data } = await ProductService.ListProduct();
        this.setState({ products: data });
    }

    handleAddProduct = async (e) => {
        e.preventDefault();

        const { payload } = this.state;

        this.setState({ loading: true, error: false, success: false });

        const upload = await ProductService.UploadImage(payload.image);

        if (upload.status === 200) {
            payload.image = upload.data.secure_url
        } else {
            return this.setState({ loading: false, error: true });
        }
        
        await ProductService.CreateProduct(payload).then(() => {
            this.componentDidMount();
            return this.setState({ loading: false, success: true });
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    handleDeleteProduct = async (id) => {
        await ProductService.DeleteProduct(id);
        this.componentDidMount();
    }

    handleChange = ({ target }) => {
        const { payload } = this.state;
        this.setState({ payload: {...payload, [target.name]: target.value} });
    }

    render() {
        const { addModal, products, payload, loading, error, success } = this.state;

        return (
            <AppLayout>
                <div className='order py-5'>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className='product'>
                                    <Row>
                                        <Col md={12}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='font-medium'>My Products</p>
                                                <Button variant='primary' className='px-4 py-2' onClick={() => this.setState({ addModal: true })}><i className='fas fa-plus-circle mr-1'></i> Add New</Button>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        {
                                            products.map((value, key) => (
                                                <Col md={3} className='px-2 mb-3' key={key}>
                                                    <div className='shadow-sm bg-white pt-5 pb-3 product-box'>
                                                        <Image src={value.image} alt={value.name} className='img-fluid' />
                                                        
                                                        <div className='d-flex justify-content-between'>
                                                            <p className='font-weight-400 px-3 mt-4 font-normal'>{value.name}</p>
                                                            <p className='font-weight-400 px-3 mt-4 font-normal'>{value.price} RWF</p>
                                                        </div>

                                                        <div className='px-3 mt-3 text-right'>
                                                            {/* <Button variant='primary' className='font-small btn-xs mr-2'><i className='fas fa-edit'></i> Edit</Button> */}
                                                            <Button variant='outline-danger' className='px-3 font-small btn-xs' onClick={() => this.handleDeleteProduct(value.id)}><i className='fas fa-trash'></i> Delete</Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Modal show={addModal} onHide={() => this.setState({ addModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Product</Modal.Title>
                        </Modal.Header>

                        <Form onSubmit={this.handleAddProduct}>
                            <Modal.Body>
                                {
                                    success && <Alert variant='success'>
                                        Product added successfully
                                    </Alert>
                                }

                                {
                                    error && <Alert variant='danger'>
                                        Something went wrong try again
                                    </Alert>
                                }

                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Eg: Mango" required autoComplete='off' value={payload?.name} onChange={this.handleChange} readOnly={loading} />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" name='price' placeholder="1000" required autoComplete='off' value={payload?.price} onChange={this.handleChange} readOnly={loading}  />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Image</Form.Label>
                                    <Form.Control type="file" required onChange={({ target }) => this.setState({ payload: {...payload, image: target.files[0]} })} />
                                </Form.Group>
                            </Modal.Body>
                            
                            <Modal.Footer>
                                <Button type='button' variant="danger" onClick={() => this.setState({ addModal: false })} disabled={loading}>
                                    Close
                                </Button>
                                <Button type='submit' variant="primary" disabled={loading}>
                                { loading ? <Spinner animation="border" size='sm' /> : 'Submit'}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                </div>
            </AppLayout>
        );
    }
}