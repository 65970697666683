import React from 'react';
import { Container, Row, Col, Table, Button, Form, InputGroup } from 'react-bootstrap';
import AppLayout from '../layouts/AppLayout';
import CheckBox from '../components/CheckBox';
import SendSMS from '../components/modal/SendSMS';
import ProductService from '../../services/ProductService';
import moment from 'moment';

export default class DatabaseView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            account: '',
            customers: [],
            activeCheckBox: [],

            smsModal: false,

            today: new Date().toISOString().slice(0, 10),

            filters: {
                start_date: new Date().toISOString().slice(0, 10),
                end_date: new Date().toISOString().slice(0, 10)
            }
        }
    }

    async componentDidMount() {
        const { data: customers } = await ProductService.ListCustomers();
        this.setState({ customers });
    }

    handleClickBox = id => {
        const { activeCheckBox } = this.state;

        const isAvailable = activeCheckBox.some(value => value === id);

        if (isAvailable) {
            const filteredCheckBox = activeCheckBox.filter(value => value !== id);
            return this.setState({ activeCheckBox: filteredCheckBox });
        }

        return this.setState({ activeCheckBox: [...activeCheckBox, id] });
    }

    handleCheckBoxAll = () => {
        const { activeCheckBox, customers } = this.state;

        if (activeCheckBox.length === customers.length) {
            return this.setState({ activeCheckBox: [] });
        }

        let customerCheckArray = [];

        for(let i = 0; i < customers.length; i++) {
            customerCheckArray = [...customerCheckArray, customers[i].phone];
        }

        return this.setState({ activeCheckBox: customerCheckArray });
    }

    handleSendModal = () => {
        const { smsModal } = this.state;
        this.setState({ smsModal: !smsModal });
    }

    handleChangeFilters = ({ target }) => {
        const { filters } = this.state;
        this.setState({ filters: { ...filters, [target.name]: target.value } })
    }

    handleSubmitFilter = async () => {
        const { filters } = this.state;

        const { data: customers } = await ProductService.ListCustomers(filters.start_date, filters.end_date);
        this.setState({ customers });
    }

    render() {
        const { activeCheckBox, customers, smsModal, today, filters } = this.state;

        return (
            <AppLayout>
                <div className='profile py-5'>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className='d-flex justify-content-between'>
                                    <p className='font-medium'>Customers Database</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col lg={12}>
                                <div className='p-4 bg-white'>

                                <Row>
                                    <Col md={7} className="mb-3">
                                        <Form.Row>
                                            <Col md={4} className="mb-2">
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text className="px-3">from</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control type="date" name="start_date" value={filters.start_date} max={today} onChange={this.handleChangeFilters} />
                                                </InputGroup>
                                            </Col>

                                            <Col md={4} className="mb-2">
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text className="px-4">To</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control type="date" name="end_date" value={filters.end_date} min={filters.start_date} max={today} onChange={this.handleChangeFilters} />
                                                </InputGroup>
                                            </Col>

                                            <Col md={2}>
                                                <Button 
                                                    variant='success' 
                                                    className="py-2 btn-block" 
                                                    onClick={this.handleSubmitFilter}
                                                >Filter</Button>
                                            </Col>
                                        </Form.Row>
                                    </Col>

                                    <Col md={5}>
                                        <Button variant='default' className='float-right text-primary' disabled={activeCheckBox.length === 0 ? true : false} onClick={this.handleSendModal}>
                                            <i className='fas fa-bullhorn'></i> Send SMS
                                        </Button>

                                        <SendSMS modal={smsModal} handleModal={this.handleSendModal} activeCheckBox={activeCheckBox} activePhone={activeCheckBox} />

                                        {/* <Button variant='default' className='float-right text-primary'>
                                            <i className='fas fa-download'></i> Download
                                        </Button> */}
                                    </Col>
                                </Row>

                                <Row className="mt-2 mobile-view">
                                    <Col md={12} className="mb-2">
                                        <label>
                                            <input type="checkbox" id="SelectAll" onClick={this.handleCheckBoxAll} checked={activeCheckBox.length === customers.length ? true : false} />
                                            <span className="font-to-normal ml-2">Select All</span>
                                        </label>
                                    </Col>

                                    {
                                        customers.map((value, key) => (
                                            <Col md={12} key={key}>
                                                <div className="w-100 p-3 shadow-sm border mb-3 cursor" onClick={() => {
                                                    this.setState({ viewPayload: value, viewModal: true })
                                                }}>
                                                    <p className="font-to-normal">
                                                        <b>{value.name}</b> 
                                                        <span className="float-right">
                                                            <CheckBox handleClickBox={this.handleClickBox} activeCheckBox={activeCheckBox} id={value.phone} />
                                                        </span>
                                                    </p>
                                                    
                                                    <p className="font-to-normal my-2"><b>{value.phone}</b> <span className="float-right">{value.orders} Orders</span></p>
                                                    
                                                    <p className="font-to-normal">
                                                        Last Order: {moment(value.last_order).format('H:mm, MMM D')}
                                                    </p>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>

                                <div className="web-view">
                                    <Table responsive className='borderless mt-4'>
                                        <thead>
                                            <tr className='borderless'>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Last Order</th>
                                                <th>Frequency</th>
                                                <th>
                                                    <input type="checkbox" onClick={this.handleCheckBoxAll} checked={activeCheckBox.length === customers.length ? true : false} /> Select All
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                customers.map((value, key) => (
                                                    <tr>
                                                        <td>{key + 1}</td>
                                                        <td>{value.name}</td>
                                                        <td>{value.phone}</td>
                                                        <td>
                                                            {moment(value.last_order).format('H:mm, MMM D')}
                                                        </td>
                                                        <td>{value.orders} Orders</td>
                                                        <td><CheckBox handleClickBox={this.handleClickBox} activeCheckBox={activeCheckBox} id={value.phone} /></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </AppLayout>
        );
    }
}