import React from "react";
import { Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import UserService from '../../../services/UserService';

export default class ProfileGeneral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            error: false,
            loading: false,

            payload: props.payload
        }
    }

    handleUpdateProfile = async (e) => {
        e.preventDefault();

        const { payload } = this.state;

        this.setState({ loading: true, error: false, success: false });
        
        await UserService.UpdateProfile(payload).then(() => {
            this.setState({ loading: false, success: true });

            return this.props.handleDone();
        }).catch(() => {
            return this.setState({ loading: false, error: true });
        });
    }

    handleChange = ({ target }) => {
        const { payload } = this.state;
        this.setState({ payload: {...payload, [target.name]: target.value} });
    }

    render() {
        const { success, error, loading, payload } = this.state;
        
        return (
            <Col md={7}>
                <div className='d-flex justify-content-between'>
                    <p className='font-medium'><i className='fas fa-building'></i> Company Profile</p>
                </div>

                <Form onSubmit={this.handleUpdateProfile} className='bg-white p-4 mt-4'>
                    {
                        success && <Alert variant='success'>
                            Company Profile updated successfully
                        </Alert>
                    }

                    {
                        error && <Alert variant='danger'>
                            Something went wrong try again
                        </Alert>
                    }

                    <Form.Group>
                        <Form.Label>Commercial Number</Form.Label>
                        <Form.Control type="text" name="commercial_name" placeholder="Eg: MTN" autoComplete='off' value={payload?.commercial_name} onChange={this.handleChange} readOnly={loading} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Registered Number</Form.Label>
                        <Form.Control type="text" name="registered_name" placeholder="Eg: MTN Group" autoComplete='off' value={payload?.registered_name} onChange={this.handleChange} readOnly={loading} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>TIN</Form.Label>
                        <Form.Control type="text" name="tin" placeholder="Eg: 123456" autoComplete='off' value={payload?.tin} onChange={this.handleChange} readOnly={loading} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" name="address" placeholder="Eg: Kigali, Rwanda" autoComplete='off' value={payload?.address} onChange={this.handleChange} readOnly={loading} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Phone / Email</Form.Label>
                        <Form.Control type="text" name="phone_or_email" placeholder="Eg: email@example.com" autoComplete='off' value={payload?.phone_or_email} onChange={this.handleChange} readOnly={loading} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as='textarea' type="text" name="description" placeholder="Enter Business description" autoComplete='off' value={payload?.description} onChange={this.handleChange} readOnly={loading} />
                    </Form.Group>

                    <Button type='submit' variant="primary" className='btn-block py-2' disabled={loading}>
                        { loading ? <Spinner animation="border" size='sm' /> : 'Save'}
                    </Button>
                </Form>
            </Col>
        );
    }
}