import axios from 'axios';

const Http = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API
});

Http.interceptors.response.use(null, error => {
	const expectedError =
		error.response &&
		error.response.status >= 400 &&
		error.response.status < 500;

	if (!expectedError) {
		console.log(error);
	}

	return Promise.reject(error);
});

export const setToken = token => {
    Http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
}

export const clearToken = () => {
    delete Http.defaults.headers.common["Authorization"];
    localStorage.removeItem('token');
}

export default Http;