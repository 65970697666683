import Http, { clearToken } from '../core/Http';

export default class UserService {
    static SearchWorkSpace(name) {
        return Http.get(`user/auth/login?workspace=${name}`);
    }

    static AuthLogin(payload) {
        return Http.post('user/auth/login', payload);
    }

    static AuthLogout() {
        Http.get('user/auth/logout');
        return clearToken();
    }

    static ListUsers() {
        return Http.get('user/account/user', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static ViewAccount() {
        return Http.get('user/account', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static CreateAccount(payload) {
        return Http.post('user/account', payload);
    }

    static CreateUser(payload) {
        return Http.post('user/account/user', payload);
    }

    static ViewProfile() {
        return Http.get('user/profile', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    }

    static UpdateProfile(payload) {
        return Http.put('user/profile', payload);
    }

    static VerifyAccount(payload) {
        return Http.post('user/account/otp/verify', payload);
    }
}